.blog__blogs {
    width: 100%;
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 1rem;
}

.blog__blogs-container {
    max-width: 1240px;
    margin-left: auto;
    margin-right: auto;
}

.blog__blogs-container_grid {
    display: grid;
    gap: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    color: #fff;
}

.blog__blogs-container_grid-card {
    background-color: var(--background-color); 
    border-radius: 0.75rem;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.blog__blogs-container_grid-card_img {
    height: 14rem;
    width: 100%;
    overflow: hidden;
}

.blog__blogs-container_grid-card_top {
    background-color: var(--background-color);
    padding: 1.5rem 1rem 0rem 1rem;
    margin: 0;
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
}

.profile-img img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.blog-info h4 {
    font-family: var(--lato);
    color: var(--header-color);
    font-weight: 500;
    font-size: 14.2px;
    line-height: 18px;
    letter-spacing: 1px;
    margin-bottom: 4px;
}

.blog-date {
    margin-right: auto;
    padding-top: 15px;
    margin-bottom: 1rem;
}

.blog-date p::before {
    content: '•';
    display: inline-block;
    font-size: 0.75em;
    margin-bottom: 5px;
    margin-right: 3px;
    vertical-align: middle;
}

.blog-date .bullet-point {
    font-family: var(--lato);
    color: #fff;
    font-weight: 300;
    font-size: 10.8px;
    line-height: 15px;
    letter-spacing: 2px; 
}

.blog__blogs-container_grid-card_bottom {
    background-color: var(--background-color);
    padding: 1rem;
    padding-bottom: 0.6rem;
    margin: 0;
}

.blog__blogs-container_grid-card_bottom p {
    font-family: var(--lato);
    color: #fff;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 2px;
}


@media screen and (max-width: 450px) {
    .blog__blogs-container_grid {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media screen and (min-width: 640px) {
    .blog__blogs-container_grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (min-width: 768px) {
    .blog__blogs-container_grid {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media screen and (min-width: 1024px) {
    .blog__blogs-container_grid {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}