.blog__publication-intro {
    background-color: var(--background-color);
    padding-top: 28vh;
}

.blog__publication-intro h1 {
    font-family: var(--lato);
    color: var(--header-color);
    font-size: 84px;
    font-weight: 500;
    line-height: 105px;
    text-align: center;
}

.blog__publication-text {
    margin: 0 auto;
    margin-top: 5rem;
    max-width: 800px;
}

.blog__publication-text p {
    font-family: var(--georgia);
    color: #4B3832;
    font-size: 20px;
    letter-spacing: 2px;
    line-height: 30px;
    margin-top: 2.5rem;
}

.blog__publication-strait h1,
.blog__publication-uts h1 {
    font-family: var(--lato);
    color: #A36C4D;
    font-weight: 300;
    font-size: 60px;
    line-height: 80px;
    letter-spacing: 2px;
    margin-bottom: 2rem;
}

.blog__publication-strait, 
.blog__publication-uts {
    margin: 0 auto;
    max-width: 850px;
}

.blog__publication-strait {
    padding: 0;
    margin-top: 6.5rem;
}

.blog__publication-uts {
    padding: 0;
    margin-top: 5rem;
}

.blog__publication-uts__subheading {
    font-family: var(--lato);
    color: #A36C4D;
    font-weight: 300;
    font-size: 40px;
    line-height: 70px;
    letter-spacing: 2px;
    margin-top: 2rem;
}

.second-heading {
    margin-top: 4rem;
}

.temporary-filler {
    font-family: var(--lato);
    color: #A36C4D;
    font-weight: 400;
    font-size: 22px;
    line-height: 40px;
    letter-spacing: 1px;
    margin-top: 1.5rem;
    text-transform: uppercase;
    text-align: center;
}

@media screen and (min-width: 1500px) {
    .blog__publication-text {
        max-width: 860px;
    }

    .blog__publication-strait, 
    .blog__publication-uts {
        max-width: 880px;
    }
}

@media screen and (max-width: 1100px) {
    .blog__publication-intro h1 {
        font-size: 62px;
        line-height: 80px;
    }

    .blog__publication-uts__subheading {
        font-size: 39px;
        line-height: 50px;
    }

    .blog__publication-text {
        max-width: 650px;
    }

    .blog__publication-text p {
        font-size: 18px;
        line-height: 26px;
    }

    .blog__publication-strait, 
    .blog__publication-uts {
        max-width: 670px;
    }

    .blog__publication-strait h1,
    .blog__publication-uts h1 {
        font-size: 54px;
        line-height: 85px;
    }

}

@media screen and (max-width: 650px) {
    .blog__publication-intro {
        padding-top: 24vh;
    }

    .blog__publication-intro h1 {
        font-size: 47px;
        line-height: 60px;
    }

    .blog__publication-uts__subheading {
        font-size: 32px;
        line-height: 62px;
        margin-top: 1rem;
    }

    .blog__publication-text {
        max-width: 300px;
    }

    .blog__publication-text p {
        font-size: 16px;
        letter-spacing: 1px;
    }

    .blog__publication-strait, 
    .blog__publication-uts {
        max-width: 320px;
    }

    .blog__publication-strait h1,
    .blog__publication-uts h1 {
        font-size: 52px;
        line-height: 56px;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 450px) {
    .blog__publication-strait h1,
    .blog__publication-uts h1 {
        font-size: 45px;

    }
}
