.blog__blogs-intro {
    background-color: var(--background-color);
    padding-top: 28vh;
}

.blog__blogs-intro h1 {
    font-family: var(--lato);
    color: var(--header-color);
    font-weight: 400;
    font-size: 86px;
    font-weight: 500;
    line-height: 75px;
    text-align: center;
    padding-bottom: 1rem;
}

.blog__blogs-text {
    margin: 0 auto;
    margin-top: 5rem;
    max-width: 800px;
}

.blog__blogs-text p {
    font-family: var(--georgia);
    font-weight: 400;
    color: #4B3832;
    font-size: 18.5px;
    letter-spacing: 2px;
    line-height: 30px;
    margin-top: 2.5rem;
}


@media screen and (max-width: 1100px) {
    .blog__blogs-intro h1 {
        font-size: 64px;
        line-height: 100px;
        padding-bottom: 0rem;
    }

    .blog__blogs-text {
        max-width: 650px;
    }

    .blog__blogs-text p {
        font-size: 17px;
        line-height: 26px;
    }
}

@media screen and (max-width: 650px) {
    .blog__blogs-intro {
        padding-top: 24vh;
    }

    .blog__blogs-intro h1 {
        font-size: 54px;
        line-height: 80px;
    }

    .blog__blogs-text {
        max-width: 300px;
    }

    .blog__blogs-text p {
        font-size: 15px;
        letter-spacing: 1px;
    }
}