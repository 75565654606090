.button {
    display: inline-block;
    background-color: white;
    border: 2px solid #4830DB;
    border-radius: 10px;
    text-align: center;
    padding: 10px;
    margin-top: 2rem;
    cursor: pointer;
} 

.button .button-text {
    font-family: var(--lato);
    color: #4830DB;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 3px;
}

@media screen and (max-width: 1100px) {
    .button {
        padding: 9px;
    }

    .button .button-text {
        font-size: 14px;
        line-height: 18px;
    }
}

@media screen and (max-width: 650px) {
    .button {
        padding: 8px;
        margin-top: 1.5rem;
    }

    .button .button-text {
        font-size: 13px;
        letter-spacing: 2px;
    }
}